import React, { useState, useEffect, useRef, useContext } from 'react';
import { auth } from './firebase';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import UserContext from './UserContext';
import seaVideo from './sea.mp4';
import boatImage from './boat.png';
import backgroundSound from './sea.mp3';
import IconButton from '@material-ui/core/IconButton';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import './HomePage.css';


function HomePage() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [audioStarted, setAudioStarted] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const audioRef = useRef(null);

    const [validUsers, setValidUsers] = useState([]);
    const [fetchComplete, setFetchComplete] = useState(false);

    useEffect(() => {
        const fetchValidUsers = async () => {
          try {
            const documentRef = doc(db, "validusers", "allusers");
            const documentSnapshot = await getDoc(documentRef);
            if (documentSnapshot.exists()) {
                const data = documentSnapshot.data();
                if (data.users) {
                    setValidUsers(data.users);
                }
            }
          } catch (error) {
            console.error("Error fetching valid users:", error);
          } finally {
            setFetchComplete(true);
          }
        };
    
        fetchValidUsers();
    }, []);

    useEffect(() => {
        const startAudio = () => {
            setAudioStarted(true);
            // Remove the event listener once audio starts
            document.removeEventListener('click', startAudio);
        };
    
        if (!audioStarted) {
            document.addEventListener('click', startAudio);
        }
    
        return () => {
            document.removeEventListener('click', startAudio);
        };
    }, [audioStarted]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = 0.5; // Adjust the volume to 50%
        }
    }, []);
    

    const googleLogin = async () => {
        const provider = new GoogleAuthProvider();

        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error("Error during Google sign-in:", error.message);
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error("Error signing out:", error.message);
        }
    };

    const updateProgressForUser = async (userEmail) => {
        try {
            const userDocRef = doc(db, 'progress', userEmail);
    
            // Check if document for user already exists
            const docSnapshot = await getDoc(userDocRef);
            
            if (!docSnapshot.exists()) {
                // If not, create a new document with the user's email and add the "solvedurls" field of array type
                await setDoc(userDocRef, {
                    solvedurls: []
                });
            }
        } catch (error) {
            console.error("Error updating progress for user:", error);
        }
    };

    const handleMemoryClick = () => {
        if (fetchComplete) {
            if (!validUsers.includes(user?.email)) {
                alert('You are not authorized to access this content.');
                return;
            }
            // Update progress for user in Firestore
            updateProgressForUser(user.email);
    
            navigate('/memorykeeper/start');
        }
    };

    const handleVRonClick = () => {
        if (fetchComplete) {
            if (!validUsers.includes(user?.email)) {
                alert('You are not authorized to access this content.');
                return;
            }
            // Update progress for user in Firestore
            updateProgressForUser(user.email);
    
            navigate('/vron');
        }
    };

    const toggleSound = () => {
        setIsMuted(!isMuted);
    };

    const setAudioVolume = () => {
        if (audioRef.current) {
            audioRef.current.volume = 0.5;
        }
    };

    return (
        <div className="home-page">
            {audioStarted && <audio ref={audioRef} src={backgroundSound} autoPlay loop muted={isMuted} onLoadedMetadata={setAudioVolume} />}
            <video autoPlay loop muted className="background-video">
                <source src={seaVideo} type="video/mp4" />
            </video>
            <div className="header">
                <div className="logo no-select no-pointer-events">Sail The Story</div>
                <IconButton color="inherit" onClick={toggleSound} className="sound-button">
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
                {!user ? (
                    <button className="custom-button" onClick={googleLogin}>
                        Login with Google
                    </button>
                ) : (
                    <button className="custom-button" onClick={logout}>
                        Logout
                    </button>
                )}
            </div>
            {user && (
                <div>
                    <div className="memory-container">
                        <div className="memory-text no-select no-pointer-events">{user.displayName}'s memories</div>
                        <img 
                            src={boatImage} 
                            alt="Boat" 
                            className="memory-image"
                            onClick={handleMemoryClick}
                        />
                    </div>
                    <div className="vron-container">
                        <div className="vron-text no-select no-pointer-events">VRon</div>
                        <img 
                            src={boatImage} 
                            alt="Boat" 
                            className="vron-image"
                            onClick={handleVRonClick}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomePage;
