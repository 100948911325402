import React, { useEffect, useState, useRef, useContext } from 'react';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from './firebase';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import styles from './VRonEndofloop.module.css';

function VRonEndofloop() {
  const { user } = useContext(UserContext);
  const [displayedTexts, setDisplayedTexts] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [visitCount, setVisitCount] = useState(0);
  const [solvedUrls, setSolvedUrls] = useState([]);
  const [error, setError] = useState(null);
  const typingTimeout = useRef(null);
  const navigate = useNavigate();
  const paragraphDict = [
    [
        `이솔은 VR헤드셋을 벗어버리고 눈물을 닦으며 A를 뚫어져라 쳐다보았다. 지긋지긋한 죽음을 피하고 싶으면서도 A에 대한 분노가 걷잡을 수 없이 커져갔다. 놀랐게도 션의 도움 덕분에 였는지 A의 행동은 느리면서 렉걸린 NPC 마냥 같은 행동을 반복하고 있었다. 이솔은 이때를 놓치지 않고 A로 부터 도망치기 위해 A의 머리를 VR헤드셋으로 세게 가격했다. 하지만 분노가 너무 컸던 나머지 너무 힘껏 가격했고 A는 뒤로 세게 넘어지다 책상 모서리에 머릴 세게 부디치고 말았다. 예기치 않게 많은 혈액이 바닥을 뒤덮였다.`,
        `이솔은 조심스레 A를 확인해봤지만 그는 이미 숨져 있었다. 그녀는 안도감과 살인으로 인한 죄책감에 어쩔 줄 몰라하며 주저 앉아 통곡을 하였다.`
    ],
    [
        `시간이 어느정도 흐른 후, 그녀는 알 수 있었다. 더 이상의 회기는 일어나지 않음을… 뒤늦게 정신을 차리고  그의 소지품을 확인했다. 지갑 속에 있는 A의 신분증과 사원증. 그 사원증엔 "VRon 전략부서, 과장 A" 라 적혀있었다. 그 사원증 뒤엔 "끝이 아니라니깐" 이라는 도대체 알 수 없는 글이 써져있었다.`,
        `당혹스런 그녀는 힘이 풀려 그만 쓰러지고 말았다.`
    ],
    [
        `잠시 후, 눈을 뜬 그녀의 눈 바로 앞으로 3D 메시지 창이 열렸다.`,
        `"축하합니다. VRon의 방탈출 게임: Infinite World를 탈출하셨습니다!"`
    ],
    [
        `머리에서 느껴지는 VR 헤드셋의 무게감이 느껴졌다. 뻐근한 목과 피로한 눈... 그녀는 지금껏 자신이 생생한 게임을 미리 테스트하고 있었음을 자각할 수 있었다. 갑갑한 헤드셋을 벗어 버리며, 그녀는 잠시동안 현실과 가상세계를 구분할 수 없었다. 테스트는 성공적이였지만 몰려오는 피로감에 그녀는 주변 카페로 향했다. `
    ],
    [
        `그녀는 카페에서 주문을 마치고 기다리는데, 왠지 모르게 낯익은 한 바리스타가 그녀에게 말을 걸었다.`,
        `"오늘도 그 동작 하셨나요? 정말 독특 하더라고요."`
    ]
  ];

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchProgress = async () => {
      try {
        const userDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const visitCount_end_of_loop = data.visitCount_end_of_loop || 0;
          setVisitCount(visitCount_end_of_loop);
          setSolvedUrls(data.solvedUrls || []);
        }
      } catch (error) {
        setError("Error fetching progress.");
        console.error(error);
      }
    };

    fetchProgress();
  }, [user]);

  useEffect(() => {
    if (paragraphDict.length > 0) {
      const fetchAndUpdateTexts = async () => {
        try {
          const pageDocRef = doc(db, 'vron_progress', user.email);
          const docSnapshot = await getDoc(pageDocRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const visitCount_end_of_loop = data.visitCount_end_of_loop || 0;
            const updatedParagraphs = paragraphDict[currentGroupIndex].map((text) => {
              return text;
            });
            if (visitCount_end_of_loop > 1) {
              setDisplayedTexts(updatedParagraphs);
              setIsTyping(false);
            } else {
              typeTexts(updatedParagraphs);
            }
          }
        } catch (error) {
          setError("Error fetching progress for updating texts.");
          console.error(error);
        }
      };

      fetchAndUpdateTexts();
    }
  }, [currentGroupIndex, user]);


  useEffect(() => {
    const handleClickOrSpace = (event) => {
      // Handle mouse click
      if (event.type === 'click' && !isTyping) {
        moveToNextGroup();
      }

      // Handle spacebar press
      if (event.type === 'keydown' && event.keyCode === 32 && !isTyping) {
        moveToNextGroup();
      }
    };

    window.addEventListener('click', handleClickOrSpace);
    window.addEventListener('keydown', handleClickOrSpace);

    return () => {
      window.removeEventListener('click', handleClickOrSpace);
      window.removeEventListener('keydown', handleClickOrSpace);
    };
  }, [isTyping, currentGroupIndex]);


  const typeTexts = (texts) => {
    setDisplayedTexts(Array(texts.length).fill(''));
    setIsTyping(true);
    let index = 0;
    const speed = 50;

    const typingEffect = () => {
      if (index < texts.length) {
        let currentText = texts[index];
        let currentIndex = 0;

        const typeParagraph = () => {
          if (currentIndex <= currentText.length) {
            setDisplayedTexts((prev) => {
              const newTexts = [...prev];
              newTexts[index] = currentText.substring(0, currentIndex);
              return newTexts;
            });
            currentIndex++;
            typingTimeout.current = setTimeout(typeParagraph, speed);
          } else {
            index++;
            typingEffect();
          }
        };

        typeParagraph();
      } else {
        setIsTyping(false);
      }
    };

    typingEffect();
  };

  const moveToNextGroup = () => {
    if (!isTyping && currentGroupIndex < paragraphDict.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    }
  };

  const handleMoveToNextChapter = async () => {
    try {
        navigate('/vron');
    } catch (error) {
        console.error("Error updating visit count for end-of-loop:", error);
    }
  };

  return (
    <div className={styles.endofloopContainer}>
      <header className={styles.endofloopHeader}>
        <h1>Chapter: End of The Loop</h1>
      </header>
      <main className={styles.endofloopMain}>
        <img src={`/img/end-of-loop/${currentGroupIndex + 1}.jpg`} alt={`Image for group ${currentGroupIndex + 1}`} className={styles.paragraphImage} />
        {displayedTexts.map((text, index) => (
          <p key={index} className={styles.typingText} style={{ minHeight: 'unset' }} dangerouslySetInnerHTML={{ __html: text }}></p>
        ))}
        {!isTyping && currentGroupIndex === paragraphDict.length - 1 && (
          <button className={styles.nextButton} onClick={handleMoveToNextChapter}>처음으로 돌아가기</button>
        )}
      </main>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default VRonEndofloop;