import React, { useEffect, useState, useRef, useContext } from 'react';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from './firebase';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import styles from './VRonInfiniteWorld.module.css';

function VRonInfiniteWorld() {
  const { user } = useContext(UserContext);
  const [displayedTexts, setDisplayedTexts] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [visitCount, setVisitCount] = useState(0);
  const [visitCountItIsA, setVisitCountItIsA] = useState(0);
  const [solvedUrls, setSolvedUrls] = useState([]);
  const [error, setError] = useState(null);
  const typingTimeout = useRef(null);
  const navigate = useNavigate();
  const paragraphDict = [
    [
      `어둠이 짙게 내려앉은 도시의 밤, 네온사인이 빛나는 거리에는 여전히 사람들의 발걸음이 끊이지 않았다. 현실의 무게를 잠시나마 내려놓기 위해 많은 이들은 가상현실 플랫폼 VRon에 접속했다. 그곳에서는 누구나 원하는 모습으로 변신하고, 현실에서는 불가능한 경험을 할 수 있었다.`,
      `아 이솔, 28세의 소프트웨어 엔지니어이자 VR 기술 분야에서 촉망받는 VRon 소속 개발자였다. 그녀는 하루의 업무를 마치면 늘 자사 VR 서비스에 접속해 마음의 안정을 찾곤 했다. 특히 그녀만의 독특한 동작은 몸과 마음을 편안하게 해주는 일종의 명상과도 같았다. 어린 시절부터 해오던 이 동작은 가족이나 친구들만 아는 습관적인 동작이었다.`
    ],
    [
      `오늘도 이솔은 평소처럼 집에 돌아와 간단한 저녁 식사를 마치고 VR 기기를 착용했다. 헤드셋이 그녀의 머리를 감싸자 현실은 서서히 사라지고, 눈앞에는 아름다운 가상 세계가 펼쳐졌다. 오늘은 마음이 조금 울적했기에 그녀는 조용한 호숫가를 선택했다. 물결이 잔잔히 일렁이는 호수와 산들바람에 흔들리는 나무들, 새들의 지저귐까지 모든 것이 완벽했다.`,
      `이솔은 깊게 숨을 들이마시며 가볍게 동작을 시작했다. 양손을 천천히 잡아당기면서 업무하느라 뻐근해진 날개죽지를 이완 시켰다. 이 동작은 그녀에게 마음의 안정을 가져다주었다. 하지만 그 순간, 그녀의 시야에 낯선 남성의 아바타가 들어와 있었다. 조금 떨어진 나무 아래에서 그 아바타는 앉아 하염 없이 자신을 바라보고 있었다.`
    ],
    [
      `순간 심장이 철렁 내려앉았다. "저 사람이 언제부터 저기 있었지?" 그녀는 눈을 비벼보았다. 남자는 여전히 같은 자세로 서 있었다. "혹시 날 보고 있던 건 아니겠지?" 이솔은 당황스러움과 함께 불안감이 엄습했다.`,
      `이윽고 그가 그녀가 하던 동작을 그대로 따라하기 시작했다. "어? 이렇게 동작을 따라하는 사람을 본 적이 없는데? 그것도 가상현실 속에서?" 그녀만이 하는 특이한 동작을 수상한 누군가가 따라 하고 있다는 사실이 믿기지 않았다.`
    ],
    [
      `그녀는 도망치듯이 VR 헤드셋을 벗어 던졌다. 괜히 불쾌하다. 그녀는 가상현실임에도 불구하고 스토킹 당한 기분이였다. 그저 마음의 평온을 찾으려 찾았던 공간이 불편했던 적은 처음이었다.`,
      `이솔은 마음이 진정되지 않았다. 현실 세계로 돌아왔음에도 불구하고 그의 시선이 계속 느껴지는 듯했다. '어쩌면 우연일지도 몰라. 그저 흔한 동작일 뿐이야.' 그녀는 스스로를 다독였지만, 머릿속에서는 그의 미소와 눈빛이 지워지지 않았다.`
    ],
    [
      `그날 밤, 침대에 누운 이솔은 쉽게 잠들 수 없었다. 불안한 마음에 휴대전화를 들어 SNS를 확인해보았다. 그런데 알 수 없는 계정으로부터 친구 요청이 와 있었다. 프로필 사진은 없었고, 이름도 특이했다. "혹시... 아까 그 사람인가?" 그녀는 곧바로 요청을 거절하고 휴대전화를 내려놓았다.`,
      `하지만 이상한 일은 그날 이후로 계속되었다. 다음 날 출근길, 지하철에서 그녀는 누군가의 시선을 느꼈다. 뒤를 돌아보았지만 사람들은 각자 스마트폰에 몰두해 있을 뿐이었다. 회사에 도착해서도 집중할 수 없었다. 동료들이 말을 걸어도 멍하니 대답할 뿐이었다.`
    ],
    [
      `점심시간, 그녀는 커피를 사러 카페에 들렀다. 주문을 마치고 기다리는데, 바리스타가 그녀에게 말을 걸었다. "오늘도 그 동작 하셨나요? 정말 독특 하더라고요."`,
      `이솔은 깜짝 놀라며 물었다. "그걸 어떻게 아세요?"`,
      `바리스타는 어리둥절한 표정으로 대답했다. "저번에 VRon에서 봤잖아요. 기억 안 나세요?"`,
      `그녀는 말을 잇지 못했다. 바리스타는 살며시 웃으며 커피를 건네주었다. '이게 도대체 무슨 일이지?' 그녀의 머릿속은 혼란스러웠다. 혹시 그 수상한 남자 아바타가 바리스타일수도 있으니 헐레벌떡 짐을 챙겨 나왔다.`
    ],
    [
      `회사로 돌아오는 길, 그녀는 깊은 생각에 잠겼다. '누군가가 날 스토킹하고 있는 걸까? 아니면 단순한 우연의 일치일까?' 하지만 아무리 생각해도 설명이 되지 않았다. 그녀의 독특한 동작을 어떻게 다른 사람들이 알고 있는 것인지 이해할 수 없었다.`
    ],
    [
      `그날 저녁, 그녀는 다시 한번 VRon에 접속하기로 결심했다. 이번에는 확실한 답을 얻고 싶었다. 가상 세계에 들어서자마자 그녀는 주변을 살폈다. 사람들은 저마다의 즐거움에 몰두해 있었다. 하지만 그녀는 그들 중 누군가가 자신을 지켜보고 있을지도 모른다는 생각에 몸서리쳤다.`,
      `호숫가로 이동한 그녀는 조용히 앉아 주변을 관찰했다. 그런데 멀리서 또 그 남자가 보였다. 이번에는 다른 사람들과 어울려 웃고 있었다. 이솔은 숨을 죽이고 그를 지켜보았다. 그는 마치 아무 일도 없었다는 듯 행동하고 있었다.`
    ],
    [
      `그녀는 그의 프로필을 확인하려 했지만, 접근이 불가능했다. '이상하네, 공개 설정이 아닌가?' 그녀는 고민 끝에 주변에 있는 사람들에게 다가가 물어보았다.`,
      `"저기, 저 사람 아세요? 저기 저기 웃고 있는 분이요."`,
      `하지만 사람들은 의아한 표정으로 대답했다. "아 저분이요? 당연히 알죠! 오늘 오신 분인데 재주가 많아요 하하"`
    ],
    [
      `이솔은 혼란스러웠다. "아니, 저번에 분명히..." 수습하면서 그 남자와 눈이 마주쳤다. 그는 이솔을 쳐다보고 있었다. 심장은 미친 듯이 뛰기 시작했다. '이게 무슨 일이야? 내가 잘못 본 건가?' 그녀는 얼른 눈길을 돌리며 대화를 마무리 지었다. “하하 저도 처음 봐서 궁금해서 물어봤어요 이만 갈 곳이 있어서” 그리곤 얼른 그 자리에서 나와 VR 헤드셋을 벗어버렸다.`,
      `현실로 돌아온 그녀는 깊은 한숨을 내쉬었다. 점점 상황이 이상해지고 있었다. 누군가가 그녀를 감시하고 있는 것만 같았다. 하지만 증거도 없고, 주변 사람들은 아무도 그를 이상한 사람으로 의식하지 않는다.`
    ],
    [
      `다음 날, 그녀의 VRon 메시지함에는 이상한 메시지가 도착해 있었다.`,
      `"무언가 통제 당하는 기분, 그치만 작은 희망을 보았어요."`,
      `이솔은 공포에 질렸다. 발신인은 익명이었고, 추적할 수 없었다. 그녀는 급하게 회사의 보안팀에 연락했지만, 그들은 별다른 도움을 주지 못했다.`
    ],
    [
      `그녀는 스스로 해결하기로 마음먹었다. 컴퓨터를 열고 VRon의 시스템 로그를 분석하기 시작했다. 평소에는 접근할 수 없는 영역이었지만, 그녀는 상관의 보안 등급을 이용해 추적하기 시작했다. 몇 시간에 걸친 작업 끝에, 그녀는 이상한 로그를 발견했다. 그것은 사용자들의 행동을 추적하고, 개인 정보를 수집하는 프로그램에 대한 로그들이였다.`,
      `"이건 불법이야... 누군가가 시스템을 이용해 유저의 모든걸 기록하고 있어." 그녀는 놀라움과 분노로 손을 떨었다. 하지만 그 순간, 화면에 또 다른 메시지가 나타났다.`,
      `"좋지 않은 짓을 하고 있네요. 그만두는 게 어떨까요?"`,
      `이솔은 뒤를 돌아보았다. 아무도 없었다. 하지만 등골이 오싹해졌다. '어떻게 알았지? 내 컴퓨터에 접근하고 있는 건가? 아니면 추적하던걸 들킨건가? 그럴 일 없을텐데…?' 그녀는 즉시 인터넷 연결을 끊고 컴퓨터를 껐다.`
    ],
    [
      `한동안 그녀는 아무것도 할 수 없었다. 두려움과 무력감이 그녀를 짓눌렀다. 하지만 포기할 수는 없었다. 그녀는 진실을 밝혀내기로 결심했다.`,
      `다음 날, 그녀는 믿을 만한 옛 친구이자 보안 전문가인 A를 찾아갔다. A는 그녀의 이야기를 진지하게 들어주었다.`,
      `"이솔, 상황이 심각해 보이네. 네 말이 사실이라면, 이건 단순한 해킹이 아니야. 누군가가 작정하고 너를 노리고 있는 것 같아."`,
      `그의 말에 이솔은 눈물을 흘릴 뻔했다. 드디어 자신을 이해해주는 사람이 나타난 것이다. "어떻게 해야 할까? 난 이제 어디서부터 시작해야 할지 모르겠어." 대화가 통하는 사람이 나타나자 그녀는 복잡한 심정을 토로하기 시작했다.`,
      `A가 그녀의 토닥이며 말했다. "걱정 마. 함께 해결해보자. 우선 네가 발견한 로그를 분석해보자고."`
    ],
    [
      `그들은 함께 밤늦게까지 작업을 했다. 코드를 추적한 결과, 그것은 단순한 해킹 프로그램이 아니었다. VRon의 시스템 깊숙이 침투한 악성 코드로, 수많은 사용자들의 정보를 수집하고 있었다. 그리고 그 정보들은 어딘가로 전송되고 있었다.`,
      `"이건 생각보다 심각한데? 누군가가 대규모로 사람들을 감시하고 있어." A의 얼굴은 창백해졌다.`,
      `이솔과 A는 서로를 바라보며 얼어붙었다. 누군가가 그들을 지켜보고 있었다. 그리고 그들은 이미 표적이 된 것이다.`,
      `공포와 절망 속에서도 이솔은 포기하지 않기로 마음먹었다. "A야, 계속 당할 순 없어! 어떻게든 막아보자…"`,
      `A는 깊게 숨을 들이마시며 고개를 끄덕였다. "그래, 맞아. 하지만 조심해야 해. 우린 감시당하고 있어, 안전하지 않아."`,
      `<span style="color: Gray; font-style: italic;">이솔은 앞으로 무슨 일이 벌어질지 가늠하지 못 했다. 가상과 현실, 각기 다른 세계 속에서 벌어지는 일들이 그녀를 혼란스럽게 했고, 이솔의 무너진 현실감은 그녀를 더욱 불안하게 했다.</span>`
    ]
  ];

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchProgress = async () => {
      try {
        const userDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const visitCount_infiniteWorld = data.visitCount_infiniteWorld || 0;
          setVisitCount(visitCount_infiniteWorld);
          setVisitCountItIsA(data.visitCount_it_is_a || 0);
          setSolvedUrls(data.solvedUrls || []);
        } else {
          await setDoc(userDocRef, { infinite_world: 0, solvedUrls: ['infinite-world'] }, { merge: true });
        }
      } catch (error) {
        setError("Error fetching progress.");
        console.error(error);
      }
    };

    fetchProgress();
  }, [user]);

  useEffect(() => {
    if (paragraphDict.length > 0) {
      const fetchAndUpdateTexts = async () => {
        try {
          const pageDocRef = doc(db, 'vron_progress', user.email);
          const docSnapshot = await getDoc(pageDocRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const visitCount_infiniteWorld = data.visitCount_infiniteWorld || 0;
            const updatedParagraphs = paragraphDict[currentGroupIndex].map((text) => {
              if (visitCount_infiniteWorld == 2) {
                // 대사 1
                text = text.replace(
                  "커피를",
                  '<span style="color: yellow; font-style: italic;">도시락을</span>'
                );
                // 대사 2
                text = text.replace(
                  "카페",
                  '<span style="color: yellow; font-style: italic;">편의점</span>'
                );
                // 대사 3
                text = text.replace(
                  "바리스타는",
                  '<span style="color: yellow; font-style: italic;">편의점 알바생은</span>'
                );
                text = text.replace(
                  "바리스타가",
                  '<span style="color: yellow; font-style: italic;">편의점 알바생이</span>'
                );
                text = text.replace(
                  "바리스타",
                  '<span style="color: yellow; font-style: italic;">편의점 알바생</span>'
                );
                // 대사 4
                text = text.replace(
                  "무언가 통제 당하는 기분, 그치만 작은 희망을 보았어요.",
                  '<span style="color: yellow; font-style: italic;">빠져나갈 방법이 있을거에요. 전 당신을 믿습니다.</span>'
                );
                // 대사 5
                text = text.replace(
                  "좋지 않은 짓을 하고 있네요. 그만두는 게 어떨까요?",
                  '<span style="color: yellow; font-style: italic;">좋지 않은 짓을 하고 있다고 말했을텐데. 이쯤에서 그만두지?</span>'
                );
              }
              if (visitCount_infiniteWorld >= 3) {
                // 대사 1
                text = text.replace(
                  "커피를",
                  '<span style="color: hotpink; font-style: italic;">타이레놀을</span>'
                );
                // 대사 2
                text = text.replace(
                  "카페",
                  '<span style="color: hotpink; font-style: italic;">약국</span>'
                );
                // 대사 3
                text = text.replace(
                  "바리스타",
                  '<span style="color: hotpink; font-style: italic;">약사</span>'
                );
                text = text.replace(
                  "바리스타",
                  '<span style="color: hotpink; font-style: italic;">약사</span>'
                );
                // 대사 4
                text = text.replace(
                  "무언가 통제 당하는 기분, 그치만 작은 희망을 보았어요.",
                  '<span style="color: hotpink; font-style: italic;">익숙해지지 마세요. 거기나 여기나 빠져나갈 길이 있을거라 믿습니다.</span>'
                );
                text = text.replace(
                  "좋지 않은 짓을 하고 있네요. 그만두는 게 어떨까요?",
                  '<span style="color: hotpink; font-style: italic;">내가 말했잖아, 해봐야 소용없다니까?</span>'
                );
              }
              return text;
            });
            if (visitCount_infiniteWorld > 1) {
              setDisplayedTexts(updatedParagraphs);
              setIsTyping(false);
            } else {
              typeTexts(updatedParagraphs);
            }
          }
        } catch (error) {
          setError("Error fetching progress for updating texts.");
          console.error(error);
        }
      };

      fetchAndUpdateTexts();
    }
  }, [currentGroupIndex, user]);

  useEffect(() => {
    const handleClickOrSpace = (event) => {
      // Handle mouse click
      if (event.type === 'click' && !isTyping) {
        moveToNextGroup();
      }

      // Handle spacebar press
      if (event.type === 'keydown' && event.keyCode === 32 && !isTyping) {
        moveToNextGroup();
      }
    };

    window.addEventListener('click', handleClickOrSpace);
    window.addEventListener('keydown', handleClickOrSpace);

    return () => {
      window.removeEventListener('click', handleClickOrSpace);
      window.removeEventListener('keydown', handleClickOrSpace);
    };
  }, [isTyping, currentGroupIndex]);


  const typeTexts = (texts) => {
    setDisplayedTexts(Array(texts.length).fill(''));
    setIsTyping(true);
    let index = 0;
    const speed = 50;

    const typingEffect = () => {
      if (index < texts.length) {
        let currentText = texts[index];
        let currentIndex = 0;

        const typeParagraph = () => {
          if (currentIndex <= currentText.length) {
            setDisplayedTexts((prev) => {
              const newTexts = [...prev];
              newTexts[index] = currentText.substring(0, currentIndex);
              return newTexts;
            });
            currentIndex++;
            typingTimeout.current = setTimeout(typeParagraph, speed);
          } else {
            index++;
            typingEffect();
          }
        };

        typeParagraph();
      } else {
        setIsTyping(false);
      }
    };

    typingEffect();
  };

  const moveToNextGroup = () => {
    if (!isTyping && currentGroupIndex < paragraphDict.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    }
  };

  const handleMoveToNextPage = async () => {
    try {
      const pageDocRef = doc(db, 'vron_progress', user.email);
      const docSnapshot = await getDoc(pageDocRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const visitCount = data.visitCount_it_is_a ? data.visitCount_it_is_a + 1 : 1;
        const updatedSolvedUrls = [...new Set([...data.solvedUrls, 'it-is-a'])];
        await updateDoc(pageDocRef, { visitCount_it_is_a: visitCount, solvedUrls: updatedSolvedUrls });
        setVisitCountItIsA(visitCount);
      } else {
        await setDoc(pageDocRef, { visitCount_it_is_a: 1, solvedUrls: ['it-is-a'] }, { merge: true });
        setVisitCountItIsA(1);
      }

      navigate('/vron/it-is-a');
    } catch (error) {
      console.error("Error updating visit count for it-is-a:", error);
    }
  };

  return (
    <div className={styles.infiniteWorldContainer}>
      <header className={styles.infiniteWorldHeader}>
        <h1>Chapter: Infinite World</h1>
      </header>
      <main className={styles.infiniteWorldMain}>
        <img src={`/img/infinite-world/${currentGroupIndex + 1}.jpg`} alt={`Image for group ${currentGroupIndex + 1}`} className={styles.paragraphImage} />
        {displayedTexts.map((text, index) => (
          <p key={index} className={styles.typingText} style={{ minHeight: 'unset' }} dangerouslySetInnerHTML={{ __html: text }}></p>
        ))}
        {!isTyping && currentGroupIndex === paragraphDict.length - 1 && (
          <button className={styles.nextButton} onClick={handleMoveToNextPage}>Next</button>
        )}
      </main>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default VRonInfiniteWorld;