import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from './firebase';
import UserContext from './UserContext';
import styles from './VRon.module.css';

function VRon() {
  const { user } = useContext(UserContext);
  const [visitCount, setVisitCount] = useState(0);
  const [solvedUrls, setSolvedUrls] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchProgress = async () => {
      try {
        const userDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setVisitCount(data.visitCount || 0);
          setSolvedUrls(data.solvedUrls || []);
        } else {
          await setDoc(userDocRef, { infinite_world: 0, solvedUrls: ['infinite-world'] }, { merge: true });
        }
      } catch (error) {
        setError("Error fetching progress.");
        console.error(error);
      }
    };

    fetchProgress();
  }, [user]);

  const handleNewStart = async () => {
    try {
      console.log("Attempting to access the 'infinite-world' page document in Firestore...");
  
      const pageDocRef = doc(db, 'vron_progress', user.email);
      const docSnapshot = await getDoc(pageDocRef);
  
      if (docSnapshot.exists()) {
        // If the document doesn't exist, create it with a visit count of 1
        console.log("No document found for 'infinite-world', creating new document with visitCount_infiniteWorld: 1");
  
        await setDoc(pageDocRef, { visitCount_infiniteWorld: 1, solvedUrls: ['infinite-world'] }, { merge: false });
        console.log("Successfully created document for 'infinite-world'.");
      }
  
      // Redirect to the infinite-world page after updating the count
      navigate("/vron/infinite-world");
    } catch (error) {
      console.error("Error updating visit count for infinite-world:", error);
    }
  };
  

  const handleContinue = async () => {
    if (solvedUrls.length > 0) {
      const pageDocRef = doc(db, 'vron_progress', user.email);
      const docSnapshot = await getDoc(pageDocRef);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log("Document data found:", data);

        const lastSolvedUrl = solvedUrls[solvedUrls.length - 1];
        if (lastSolvedUrl === 'infinite-world') {
          const visitCount_infiniteWorld = data.visitCount_infiniteWorld + 1;
          console.log("Updating visit count for infinite-world:", visitCount_infiniteWorld);
          await updateDoc(pageDocRef, { visitCount_infiniteWorld: visitCount_infiniteWorld, solvedUrls: ['infinite-world'] });
        }
      }
      const lastSolvedUrl = solvedUrls[solvedUrls.length - 1];
      navigate(`/vron/${lastSolvedUrl}`);
    }
  };

  return (
    <div className={styles.vronContainer}>
      <div className={styles.rain}>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
        <div className={styles.raindrop}></div>
      </div>

      <header className={styles.vronHeader}>
        <h1>Mystery Game: VRon</h1>
      </header>
      <main className={styles.vronMain}>
        <div className={styles.buttonContainer}>
          <button onClick={handleNewStart} className={styles.startButton}>
            처음부터 (Start from the beginning)
          </button>
          {solvedUrls.length > 0 && (
            <button onClick={handleContinue} className={styles.continueButton}>
              이어하기 (Continue)
            </button>
          )}
        </div>
      </main>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default VRon;
