import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import UserContext from './UserContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  const [validUsers, setValidUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchValidUsers = async () => {
      try {
        const documentRef = doc(db, "validusers", "allusers");
        const documentSnapshot = await getDoc(documentRef);
        if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();
            if (data.users) {
                setValidUsers(data.users);
            }
        }
      } catch (error) {
        console.error("Error fetching valid users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchValidUsers();
  }, []);

  if (loading) {
    return null;  // or a loading spinner
  }

  if (user && validUsers.includes(user.email)) {
    return children;
  }

  // Navigate to not authorized page or home page etc.
  return <Navigate to="/" />;
};

export default ProtectedRoute;
