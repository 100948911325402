// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAT_AnUkS-fTiAbp9LSnQKGIf3VH39VeYM",
//   authDomain: "sailthestory-7b483.firebaseapp.com",
//   projectId: "sailthestory-7b483",
//   storageBucket: "sailthestory-7b483.appspot.com",
//   messagingSenderId: "493291876809",
//   appId: "1:493291876809:web:b39c09cff64f151d003818",
//   measurementId: "G-KNLEE40GPS"
// };

// // Initialize Firebase
// // const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

// firebase.initializeApp(firebaseConfig);
// export default firebase;

import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAT_AnUkS-fTiAbp9LSnQKGIf3VH39VeYM",
  authDomain: "sailthestory-7b483.firebaseapp.com",
  projectId: "sailthestory-7b483",
  storageBucket: "sailthestory-7b483.appspot.com",
  messagingSenderId: "493291876809",
  appId: "1:493291876809:web:b39c09cff64f151d003818",
  measurementId: "G-KNLEE40GPS"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

// const fetchAllDocuments = async () => {
//   const docCollection = collection(db, "validurls");
//   const querySnapshot = await getDocs(docCollection);
//   const documents = querySnapshot.docs.map(doc => doc.data());
//   console.log(documents);
// };

// fetchAllDocuments();

export { db, auth };
