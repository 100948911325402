import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, arrayUnion, updateDoc } from "firebase/firestore";
import { db } from './firebase';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import UserContext from './UserContext';
import './DreamConstructor.css';

function DreamConstructor() {
  const { user } = useContext(UserContext);
  const { searchTerm } = useParams();  
  const navigate = useNavigate();

  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [validUrls, setValidUrls] = useState([]);
  const [solvedUrls, setSolvedUrls] = useState([]);
  const [validUsers, setValidUsers] = useState([]);

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!searchTerm) {
        navigate("/memorykeeper/start");
    }
  }, [navigate, searchTerm]);

  useEffect(() => {
    const fetchValidUrls = async () => {
      try {
        const documentRef = doc(db, "validurls", "allurls");
        const documentSnapshot = await getDoc(documentRef);
        if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();
            if (data.urls) {
                setValidUrls(data.urls);
            }
        }
      } catch (error) {
        console.error("Error fetching valid URLs:", error);
      }
    };

    fetchValidUrls();
  }, []);

  useEffect(() => {
    const fetchValidUsers = async () => {
      try {
        const documentRef = doc(db, "validusers", "allusers");
        const documentSnapshot = await getDoc(documentRef);
        if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();
            if (data.users) {
                setValidUsers(data.users);
            }
        }
      } catch (error) {
        console.error("Error fetching valid users:", error);
      }
    };

    fetchValidUsers();
}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const documentRef = doc(db, "dreamContent", searchTerm);
        const documentSnapshot = await getDoc(documentRef);
        // const doc = await db.collection('dreamContent').doc(searchTerm).get();
        if (documentSnapshot.exists) {
            const data = documentSnapshot.data();
            setContent(data);
        } else {
            setError("Content not found!");
        }
      } catch (error) {
        // setError("An error occurred while fetching content.");
      }
    };

    fetchData();
  }, [searchTerm]);

  const updateSolvedUrlsForUser = async (userEmail, urlsToAdd) => {
    try {
        const userDocRef = doc(db, 'progress', userEmail);
        // Update the 'solvedurls' array to include the content name
        await updateDoc(userDocRef, {
            solvedurls: arrayUnion(...urlsToAdd)
        });
    } catch (error) {
        console.error("Error updating solved URLs for user:", error);
    }
  };

  useEffect(() => {
    const fetchSolvedUrlsForUser = async () => {
        if (!user || !user.email) return; // Ensure there's a user with an email

        try {
            const userDocRef = doc(db, 'progress', user.email);
            const docSnapshot = await getDoc(userDocRef);
            
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                if (data.solvedurls) {
                    setSolvedUrls(data.solvedurls);
                }
            }
        } catch (error) {
            console.error("Error fetching solved URLs for user:", error);
        }
    };

    fetchSolvedUrlsForUser();
  }, [user]); // Dependency array set to user


  const handleSearch = async () => {
    const transformedTerm = searchInput.toLowerCase().replace(/[^a-z0-9]+/g, '');
    if (!validUsers.includes(user.email)) {
      navigate('/');
      setSearchInput('');
      return;  // Exit the function early
    }
    if (transformedTerm === content.ans) {
      await updateSolvedUrlsForUser(user.email, [searchTerm, content.next]);
      setSolvedUrls(prev => [...prev, searchTerm, content.next]);
      // console.log(content.next);
      const next = content.next;
      try {
          const documentRef = doc(db, "dreamContent", next);
          const documentSnapshot = await getDoc(documentRef);  // add await here
          if (documentSnapshot.exists) {
              const data = documentSnapshot.data();
              setContent(data);
              // console.log(data);
          }
      } catch (error) {
          // setError("An error occurred while fetching content.");
      }
      navigate(`/memorykeeper/${content.next}`);
      window.scrollTo(0, 0);
      setSearchInput('');
    }
  };

  const handlePrevious = () => {
    if (content?.prev && solvedUrls.includes(content.prev)) {
        navigate(`/memorykeeper/${content.prev}`);
        window.scrollTo(0, 0);
    }
  }

  const handleForward = () => {
    if (content?.next && solvedUrls.includes(content.next)) {
        navigate(`/memorykeeper/${content.next}`);
        window.scrollTo(0, 0);
    }
  }

  let baseUrlWithStart;
  if (content && content.youtube) {
      if (content.youtube.includes('?')) {
          baseUrlWithStart = `${content.youtube}&start=0&autoplay=1&rel=0`;
      } else {
          baseUrlWithStart = `${content.youtube}?start=0&autoplay=1&rel=0`;
      }
  } else {
      baseUrlWithStart = null;
  }

  // console.log("Current content.prev:", content?.prev);
  // console.log("Current content.next:", content?.next);
  // console.log("Current solvedUrls state:", solvedUrls);

  return (
    <div className='dream-container'>
      <header className="dream-header">
        Memory Keeper
      </header>
      <main className='main-container'>
        {content && (
          <div className="content-container">
            <img src={`/img/${content.img}.jpg`} alt={content.img} className='img'/>
            {content.guide && <div className="message-container">{content.guidemsg}</div>}
            <h2>{content.title}</h2>

            {content?.contents && (<p className='contents-container'>Hint: {content.contents}</p>)}
          </div>
        )}
        <div className="search-container">
          <ArrowBack 
            onClick={handlePrevious} 
            className={`navigation-icon ${content?.prev && solvedUrls.includes(content.prev) ? 'active-icon' : 'inactive-icon'}`} 
          />
          <div className="input-section">
          <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                      handleSearch();
                  }
              }}
              placeholder="Search..."
          />
            <button onClick={handleSearch}>Search</button>
          </div>
          <ArrowForward 
            onClick={handleForward} 
            className={`navigation-icon ${content?.next && solvedUrls.includes(content.next) ? 'active-icon' : 'inactive-icon'}`} 
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        {content?.youtube && (
          <div className="youtube-player">
            <iframe
                width="100%"
                height="315"
                src={baseUrlWithStart}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
        )}
      </main>
    </div>
  );
}

export default DreamConstructor;
