import React, { useEffect, useState, useRef, useContext } from 'react';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from './firebase';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import styles from './VRonItisa.module.css';

function VRonItisa() {
  const { user } = useContext(UserContext);
  const [displayedTexts, setDisplayedTexts] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [visitCount_infiniteWorld, setVisitCount_infiniteWorld] = useState(0);
  const [visitCount_it_is_aloop, setVisitCount_itisaloop] = useState(0);
  const [solvedUrls, setSolvedUrls] = useState([]);
  const [error, setError] = useState(null);
  const typingTimeout = useRef(null);
  const navigate = useNavigate();
  const paragraphDict = [
    [
        `깊어가는 밤, 창밖에는 비가 내리기 시작했다. 빗방울 소리가 마치 경고처럼 들려왔다. 하지만 그녀의 눈빛은 결의에 차 있었다. 두려움 속에서도 그녀는 희망을 버리고 스토킹 당하며 언제 어떤 일을 당할지 모르며 살고 싶진 않았다.`
    ],
    [
        `날이 밝았다. 우선 의심받지 않게 끔 행동햐야 한다. 내키지는 않지만 그녀는서둘러 준비를 하고 회사로 향했다. 아무 일 없는 듯 그녀는 주변 직장 동료들과 인사를 하며 그녀의 자리에 앉았다. 평소처럼 그 동안 온 이메일과 메시지를 훑었다.`,
        `"어?"`,
        `당황스럽게도 보안 체계 변경 공지가 올라와 있었다. 유저 데이터와 관련 프로그램 액세스 등급이 하나 더 높아진다는 내용이다. 이제 하루 뒤면 상관의 보안 등급마저 쓸모 없어진다. 그녀는 조바심이 나기 시작했다. 일단 그녀는 저녁에 A를 자신의 집으로 초대했다. 최대한 많은 걸 알아내기 위해선 A의 도움이 절실하다.`
    ],
    [
        `일단 그녀는 상관에게서 아프단 변명을 하고 조기 퇴근을 얻어 냈다. 집에 오는 길 마저도 누군가 나를 쳐다보는 것 아닐까 혹시 누군가 납치를 하지 않을까 온갖 걱정을 하며 도망치듯 길을 나섰다. 하지만 그럴 걱정도 없이 미리 집앞까지 와 있는 A를 맞이하며 집으로 들어왔다.`,
        `난감하다. 무엇을 해야 벗어날 수 있을 지 모르겠다. 일단 A에게 범인을 특정할 수 있는 방법에 대해 물어봤다.`,
        `A는 근심에 찬 표정과 함께 말했다. “시간이 없으니까 하는 말이지만 가장 좋은 방법이 있어. 단지, 너가 안전할지 잘 모르겠단 말이지…”`,
        `말을 흐리는 A에게 결의에 찬 목소리로 이솔은 말했다. “말해봐! 잡을 수만 있다면 다 괜찮아”`
    ],
    [
        `그러자 A는 조심스럽게 VR헤드셋을 쳐다보았다. 약간의 침묵이 지난 후 A는 걱정된 말투로 말했다. “가장 쉽고 빠른 방법은 직접 마주치는 거야, 그러면 더 확실한 단서를 얻을 수 있어. 일단 VRon에 접속하고 그와 마주쳤을 때 내가 최대한 정보를 빼올게”`,
        `이솔을 그를 다시 만나야한다는 불안감이 엄습했지만 달리 다른 방법이 없었다. A를 믿고 해보는 수 밖에. 그녀는 VR헤드셋을 쓰며 A에게 말했다. “내가 마주치면 바로 알려줄게!”`
    ],
    [
        `아무 대답없는 A를 뒤로 하고 그녀를 VRon에 접속했다. 아주 익숙한 풍경, 퇴근 후 피로를 달래주는 평온한 풍경을 뒤로 한 채 그녀는 주변을 두리번 거렸다. 아직 늦은 시간이 아님에도 불구하고 꽤 많은 유저들이 드문드문 무리지어 있었다. 괜시리 더 다급해진 그녀는 주변부를 시작으로 그룹별로 유심히 그 남자를 찾고 있었다.`,
        `그 남자의 아바타 행색을 물어보고 다녔지만 별 다른 소득이 없었다. 시간이 좀 지나서 구석진 곳에서 그 남자에 대한 정보를 얻을 수 있었다. 두 가지인데, 하나는 그의 이름은 “션”이라는 것과 다른 하나는 그 남자는 말을 하지 못한다는 것이었다. 그가 말을 하지 못 한다는 사실에 더욱 더 불안감이 엄습해 왔다. 과연 말을 진짜 하지 못하는 걸까 아니면 일부러 안 하는 걸까. 온갖 안 좋은 생각들이 몰려왔다.`
    ],
    [
        `조급한 표정을 숨기며 그녀는 다른 유저에게도 물어봤다. “션이라는 분을 아세요? 제가 그분을 찾고 있거든요”`,
        `말이 끝나기가 무섭게 그 유저는 눈이 커지며 이렇게 말했다. “아마 션도 당신을 찾고 있을 거에요. 여기 온 이유가 누굴 찾으러 왔나보더라구요. 아마도 그게 당신인가봐요”`,
        `이솔은 갑자기 숨이 턱 막혔다. 그 유저 뒷 편으로 익숙한 눈빛이 자신을 향한다는 사실을 알 수 밖에 없었다. 이솔을 A에게 소리쳤다. “A야 찾았어 바로 앞에 있어…!”`
    ],
    [
        `아무 대답도 없는 A를 두고 그녀는 굳을 수 밖에 없었다. 순식간에 그 남자가 자기 앞에 와 있었다. 이솔은 당장이라도 소리치고 싶어 눈물이 났다. 글썽이는 그녀의 앞에 그 남자는 조그만한 곰인형을 들고 있었다.`,
        `“곰인형…? 갑자기 이걸 왜? 그리고 분명 빈손이였는데 어디서 난거지...” 말문이 막혀 쳐다보기만 하는 그녀에서 살며시 그 곰인형을 안겨 주었다.`,
        `몹시 당황스럽다. 수상한 그 남자가 곰인형을 안겨주다니. 도대체 무슨 이유로? 그 남자는 웃으며 글씨가 적힌 포스트 잇을 곰인형에게 붙여주었다. “안녕 난 션이라고 해”`
    ],
    [
        `그녀는 그 포스트 잇을 읽으며 좀 전에 어떤 유저가 알려준 션은 말을 하지 못한다는 사실을 기억해 냈다. 그녀는 션에게 나지막하게 말했다. “난 이솔이야…”`,
        `그러자 션은 적잖이 당황해 보였다. 다른 포스트 잇에 “사실 나도 이솔이야”라고 쓰는 걸 본 이솔은 혼란스러웠다. 날 갖고 장난 치나 싶을 때 쯤, 그는 다시 재빨리 적어나가고 있었다. “내 이름도 이솔이고 고아라 성을 몰라. 션은 다른 사람들이 붙여준 이름이고”`,
        `딱히 그의 행동에서 수상함을 느낄 수는 없었다. 그녀는 션에게 물어봤다. “왜 나를 찾고 있었어?”`,
        `그는 수줍게 “나도 너를 따라서 이 세계를 떠나고 싶어!”라고 적어주었다.`
    ],
    [
        `그녀는 이해가 되지 않는다. 설마… “너 무슨 말을 하는 거야. 여기가 너가 평생 사는 곳이 아닐거 잖아. 헤드셋을 벗어”`,
        `션도 이해가 안된다는 눈치다. 그는 재빠르게 “나 여기 살아. 어렸을 때부터 쭉. 내가 사는 곳에서 여기까지 오기 힘들었어”라며 적을 걸 보여주더니 다시 적어나가기 시작했다. “난 마법사야 순간이동이든 물건 소환이든 다양한 재주를 부릴 수 있지”`
    ],
    [
        `그녀는 이제야 조금씩 퍼즐이 맞춰진다. 그는 아마도 NPC같은 프로그램의 한 종류일 것이다. 그치만 NPC가 현실로 나갈 수 없는 노릇. 그녀는 그를 이해해보고자 물었다. “그래서 왜 외부세상으로 나가고 싶어하는 거야? 거기가 행복하지 않니?”`,
        `그녀의 질문에 그는 어린 아이처럼 겁 먹은 표정과 함께 적어나가기 시작했다. “처음엔 그랬어, 모두가 날 좋아하는 줄 알았는데 알고보니 내 능력 때문이었어…” 그는 조금 희망 찬 표정으로 다시 적기 시작했다. “그래도 다른 세상에선 걱정없이 평온한 삶이 기다리고 있지 않을까?”`
    ],
    [
        `그녀는 어린아이 대하듯 말했다. “밖에도 그렇지 않아. 이 세상이나 바깥 세상이나 똑같아.”`,
        `이솔의 말에 절망한 션을 두고 그녀는 문득 지금껏 무엇을 하고 있었는 지 생각났다. 그녀가 지금까지 션을 스토킹의 범인으로 염두 했지만 그것이 사실일 확률이 매우 낮을 것 같다는 것과 그녀가 너무 과한 의미부여를 한게 아닐까 하는 생각이 들었다. A를 불러 지금까지의 사실들을 자총지총 설명했다. 그리고 션과 한동안 대화를 나누던 중. 별일 아닌 일에 A에게까지 너무 고생을 시킨 것 아닌가 하는 미안함이 물밀듯이 몰려왔다. 션과 급한 작별인사를 끝으로 그녀는 VR헤드셋을 벗는 동시에 그녀는 말했다. “A야 미안해, 내가 널 괜히 불렀나 보다…”`
    ],
    [
        `그녀에 눈 앞는 A가 멋쩍게 웃으며 말했다. “아냐아냐 괜찮아… <span style="color: gray; font-style: italic;">내가 원해서 온거야</span>”`,
        `이솔은 당황하며 다시 물었다. “뭐라고? 마지막에 뭐라고 했어?”`,
        `A는 그녀의 질문엔 무시한 채 그녀에게 물었다. “정말 션이 범인이라고 생각한거야?”`,
        `이솔은 지금 이 상황이 이해가 가지 않는다. 지금껏 공감해주고 도와준 사람이 한심하단듯 물어본다니… 그리고 심지어 트래킹 로그를 발견한 사람도 A다. 친구로서는 몰라도 적어도 보안전문가로서 상당히 의심한 정황을 발견해놓고…`,
        `이솔은 순간 분노에 휩싸여 언성을 높여 말했다. “너도 수상한 로그를 봤잖아! 그리고 의심할 만 했어!”`
    ],
    [
        `소리를 지르는 이솔을 앞에 두고 A는 웃기 시작했다. “<span style="color: gray; font-style: italic;">하하 다들 그렇게 말하더라! 그래서 뭐가 달라져?</span>”`,
        `이솔은 그의 웃음으로 부터 무언가 잘못됐다는 생각이 들기 시작했다. 하지만 그녀는 이미 늦었음을 알아차렸다. 조금씩 뒷 걸음 치는 그녀를 비웃으며 A는 조금씩 다와왔다. 무섭다. 제발 이 세상이 현실이 아니길 기도했다. 조여오는 A 앞에서 그녀는 조금씩 흐느끼기 시작했다. 너무 억울하다. 제발 이 순간이 끝이 아니길 그녀는 흐느껴 울며 A에게 빌었다. 그럼에도 불구하고 A는 익숙한듯 그녀를 제압한 후 손과 발을 묶었다.`
    ],
    [
        `그리고 그는 지겹다는 듯이 그녀를 마주보며 목을 서서히 조르기 시작했다. 숨을 쉴 수가 없다. 답답하다. 이게 끝인가 싶을 때쯤 A가 자신도 억울한 표정으로 말했다. “<span style="color: gray; font-style: italic;">너가 선택한 거잖아, 난 선택의 여지 조차 없었다고…!</span>”`,
        `희미한 시야 주변으로 그녀에게 밤이 찾아왔다.`
    ],
  ];

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchProgress = async () => {
      try {
        const userDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const visitCount_it_is_a = data.visitCount_it_is_a || 0;
          setVisitCount_itisaloop(data.visitCount_it_is_a || 0);
          setSolvedUrls(data.solvedUrls || []);
        }
      } catch (error) {
        setError("Error fetching progress.");
        console.error(error);
      }
    };

    fetchProgress();
  }, [user]);

  useEffect(() => {
    if (paragraphDict.length > 0) {
      const fetchAndUpdateTexts = async () => {
        try {
          const pageDocRef = doc(db, 'vron_progress', user.email);
          const docSnapshot = await getDoc(pageDocRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const visitCount_it_is_a = data.visitCount_it_is_a || 0;
            const updatedParagraphs = paragraphDict[currentGroupIndex].map((text) => {
              if (visitCount_it_is_a > 1) {
                return text.replace(
                  "traveler who found themselves in a strange",
                  '<span style="color: yellow; font-style: italic;">hello world, how are you doing</span>'
                );
              }
              return text;
            });
            if (visitCount_it_is_a > 1) {
              setDisplayedTexts(updatedParagraphs);
              setIsTyping(false);
            } else {
              typeTexts(updatedParagraphs);
            }
          }
        } catch (error) {
          setError("Error fetching progress for updating texts.");
          console.error(error);
        }
      };

      fetchAndUpdateTexts();
    }
  }, [currentGroupIndex, user]);

  useEffect(() => {
    const handleClickOrSpace = (event) => {
      // Handle mouse click
      if (event.type === 'click' && !isTyping) {
        moveToNextGroup();
      }

      // Handle spacebar press
      if (event.type === 'keydown' && event.keyCode === 32 && !isTyping) {
        moveToNextGroup();
      }

      if (event.key === 'a' || event.keyCode === 65) {
        handleMoveToNextChapter();
      }
    };

    window.addEventListener('click', handleClickOrSpace);
    window.addEventListener('keydown', handleClickOrSpace);

    return () => {
      window.removeEventListener('click', handleClickOrSpace);
      window.removeEventListener('keydown', handleClickOrSpace);
    };
  }, [isTyping, currentGroupIndex]);

  const typeTexts = (texts) => {
    setDisplayedTexts(Array(texts.length).fill(''));
    setIsTyping(true);
    let index = 0;
    const speed = 50;

    const typingEffect = () => {
      if (index < texts.length) {
        let currentText = texts[index];
        let currentIndex = 0;

        const typeParagraph = () => {
          if (currentIndex <= currentText.length) {
            setDisplayedTexts((prev) => {
              const newTexts = [...prev];
              newTexts[index] = currentText.substring(0, currentIndex);
              return newTexts;
            });
            currentIndex++;
            typingTimeout.current = setTimeout(typeParagraph, speed);
          } else {
            index++;
            typingEffect();
          }
        };

        typeParagraph();
      } else {
        setIsTyping(false);
      }
    };

    typingEffect();
  };

  const moveToNextGroup = () => {
    if (!isTyping && currentGroupIndex < paragraphDict.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    }
  };

  const handleMoveToInitialPage = async () => {
    try {
      const pageDocRef = doc(db, 'vron_progress', user.email);
      const docSnapshot = await getDoc(pageDocRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const visitCount = data.visitCount_infiniteWorld ? data.visitCount_infiniteWorld + 1 : 1;
        const updatedSolvedUrls = [...new Set([...data.solvedUrls, 'infinite-world'])];
        await updateDoc(pageDocRef, { visitCount_infiniteWorld: visitCount, solvedUrls: updatedSolvedUrls });
        setVisitCount_infiniteWorld(visitCount);
      } else {
        await setDoc(pageDocRef, { visitCount_infiniteWorld: 1, solvedUrls: ['infinite-world'] }, { merge: true });
        setVisitCount_infiniteWorld(1);
      }

      navigate('/vron/infinite-world');
    } catch (error) {
      console.error("Error updating visit count for infinite-world:", error);
    }
  };

  const handleMoveToNextChapter = async () => {
    try {
        const pageDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(pageDocRef);
    
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const visitCount = data.visitCount_it_is_aloop ? data.visitCount_it_is_aloop + 1 : 1;
            const updatedSolvedUrls = [...new Set([...data.solvedUrls, 'it-is-a-loop'])];
            await updateDoc(pageDocRef, { visitCount_it_is_aloop: visitCount, solvedUrls: updatedSolvedUrls });
            setVisitCount_itisaloop(visitCount);
        } else {
            await setDoc(pageDocRef, { visitCount_it_is_aloop: 1, solvedUrls: ['it-is-a-loop'] }, { merge: true });
            setVisitCount_itisaloop(1);
        }
    
        navigate('/vron/it-is-a-loop');
    } catch (error) {
        console.error("Error updating visit count for it_is_a-loop:", error);
    }
  };

  return (
    <div className={styles.itisaContainer}>
      <header className={styles.itisaHeader}>
        <h1>Chapter: It is a...</h1>
      </header>
      <main className={styles.itisaMain}>
        <img src={`/img/it-is-a/${currentGroupIndex + 1}.jpg`} alt={`Image for group ${currentGroupIndex + 1}`} className={styles.paragraphImage} />
        {displayedTexts.map((text, index) => (
          <p key={index} className={styles.typingText} style={{ minHeight: 'unset' }} dangerouslySetInnerHTML={{ __html: text }}></p>
        ))}
        {!isTyping && currentGroupIndex === paragraphDict.length - 1 && (
          <button className={styles.nextButton} onClick={handleMoveToInitialPage}>Next</button>
        )}
      </main>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default VRonItisa;