import React, { useEffect, useState, useRef, useContext } from 'react';
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from './firebase';
import UserContext from './UserContext';
import { useNavigate } from 'react-router-dom';
import styles from './VRonItisaloop.module.css';

function VRonItisaloop() {
  const { user } = useContext(UserContext);
  const [displayedTexts, setDisplayedTexts] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [visitCount, setVisitCount] = useState(0);
  const [visitCount_it_is_a_loop, setVisitCount_itisaloop] = useState(0);
  const [visitCount_end_of_loop, setVisitCountEndvisitCount_end_of_loop] = useState(0);
  const [solvedUrls, setSolvedUrls] = useState([]);
  const [error, setError] = useState(null);
  const typingTimeout = useRef(null);
  const navigate = useNavigate();
  const paragraphDict = [
    [
        `내 이름은 이솔, 언제 지어졌는지 도 모르고 고아로 태어났다. 하지만 이상하게 모두가 날 션이라 부른다. 나한테는 션이라는 이름이 더 잘 어울린다나? 자유로운 영혼인 날 션이라는 이름에 한정시키는 게 불편하다. 난 아주 능력있는 능력자인데 말이다. 흔히 말하는 마법사라는 불리우는 대단한 사람이란 말이다. 순간 이동도 가능하고 뭐든 뚝딱 만들어 낼 수 있다. 나도 내가 어떻게 할 수 있는지는 잘은 모르겠다. 그저 상상만 하면 그것이 현실이 된다. 모두가 부러워 하지만 나에겐 치명적인 단점이 있다. `,
        `"..."`,
        `말을 하지 못한다. 아마 날 낳으신 부모님은 그래서 날 버렸을 거다. 그치만 난 괜찮다. 밖에 나가면 모두가 날 좋아해준다. 능력도 좋은 지라 내가 아는 이들은 날 항상 찾아와 준다.`

    ],
    [
        `그러던 어느 날, 동네 애들이 몰려와 션에서 소형 폭탄 모형을 만들어 달라고 했고 션은 내키지 않았지만 익숙하게 만들어 주었다. 아이들은 술래잡기 할 때 술래가 들고 있을 거라고 설득했고 모형은 괜찮겠지라는 생각을 하며 말들어 주었다.`,
        `잠시 후, 엄청난 폭음 소리와 함께 많은 아이들이 폭탄에 피할 세도 없이 모두 숨지는 사고가 일어났다. 사실관계를 파악한 동네사람들은 법과 제도라는 선을 벗어나 션에게 보복하기 위해 나섰고, 그는 망연자실할 세도 없이 모든 동네 사람들로 부터 쫓기게 되었다. 이 사실을 아는 몇몇 이들은 션을 구해주려다 모두 가차없이 살해 당하고 말았다. 다행이도 션은 그들의 도움으로 멀리 도망갈 수 있었다.`
    ],
    [
        `간신히 도망쳐 온 곳에서 그는 잠시나마 되짚어 보았다. 분명 션은 아이들에게 모형 폭탄을 주었다. 빈 플라스틱으로 된 가벼운 모형 말이다. 혹시나 하는 마음에 션은 죄책감에 빠져들기 시작했다. 모두에게 사랑받던 동네였는데 모두가 그를 싫어하게 된 동네가 되어버렸고 그를 진심으로 사랑한 이들은 그를 구하기 위해 죽음을 맞이했다. 션이 사랑하던 모든 것을 한순간에 날려버리게 되었고, 그는 실의에 빠져 그 동안의 삶마저 부정하기 시작했다. 더 이상 삶의 이유를 잃은 그는  무작정 자신의 동네로 부터 먼 곳으로 떠나기 시작했다.`,
        `그 후, 그가 다다른 곳은 매우 한적한 호숫가 였다. 근처 나무 아래에 앉아 한숨을 내쉬었다. 우울감에 빠졌던 그는 주변에 없었던 사람들이 갑자기 종종 나타나는 모습을 보았다. 그는 그들이 자신과 같은 존재일거라 생각했고 용기를 내어 그들에게 다가가야겠다고 생각했다.`
    ],
    [
        `주변을 돌아다녀 보니, 호수 한 곳에 그들을 위한 쉼터가 있는 것 같았다. 션은 그 주변 사람들에게 눈도장을 찍으며 조금씩 가까워져갔다. 그들은 아쉽게도 처음부터 그 곳에 살던 사람들 이였다. 그럼에도 그는 흥미로운 사실을 알게되었는데, 갑작스럽게 나타나는 이들은 다른 세상에 사는 사람들이 종종 이 세계로 휴식이나 유흥을 즐기러 온다는 것이였다.`,
        `다음 날, 그는 갑자기 나타나는 한 여자를 보았고 왠지 모를 친근함에 조심스레 그녀를 바라보았다. 그녀는 익숙한듯 호숫가 근처에 앉아 큰 숨을 들이 마시며 동작을 시작했다. 그는 자신이 하던 비슷한 동작에 자기도 모르게 그 동작을 따라하고 있었다. 그 여자는 션의 존재를  알아차리고, 갑작스레 사라져버렸다. 아마도 그가 그녀의 동작을 따라했기에 수상하게 생각했나보다. 괜스레 미안해진 션은 다음엔 꼭 사과를 해야겠다는 마음에 주변 사람들에게 그녀를 닮은 사람을 보면 자신에게 알려달라고 당부했다.`
    ],
    [
        `그렇게 하루가 지나고 션은 그녀가 다시 돌아올까 그녀를 처음 만났던 그 곳 주변에서 사람들과 어울리며 그녀를 기다렸다. 사람들에게 다가가는 데는 그의 능력이 큰 도움이 됐다. 다양한 소환술을 선보이며 그들을 흥미롭게 만들었다. 그러던 사이 사람들 속에서 그녀를 보았다. 그는 다급하게 그녀에게 다가갔고 사과의 의미로 그녀에서 곰인형을 건넸다. 사회성이 부족한 션에게는 그저 곰인형이 여자아이에게 사랑받는 장난감으로 알고 있었기에 그녀도 좋아하리라 생각했다. 하지만 그녀는 당황한 표정을 지으며 그를 쳐다봤고, 션은 다급히 포스트잇을 꺼네어 해명하려 했다.`,
        `그렇게 그와 그녀의 대화가 있은 후, 그는 바깥세상의 존재에 대해서 알게 되었고 그녀와의 오해도 풀게 되었다. 하지만 션이 존재하는 세계는 그저 다른 세상을 위해 존재한다는 느낌을 지울 순 없었고, 이는 그에게 인생의 회의감을 주었다. 무기력해진 그는 숙소에 돌아와 삶의 이유를 찾기 시작했다.`
    ],
    [
        `그는 모두가 그를 좋아해줬던 이유를 그의 능력때문이라 생각했고, 왜 자신이 그러한 능력이 있는지 생각해 봤지만 그 이유는 찾을 수 없었다. 우울감에 빠진 그는 이제 더 이상 잃을 것도 없다고 생각했다. 그는 마지막을 장식하기 위해 야밤에 조용한 호숫가로 향했다. 그토록 모든 이들이 사랑해줬던 능력이 그의 끝을 장식할 줄을 미처 몰랐다. 눈물을 흘리며 그의 모든 것을 앗아갔던 소형 폭탄을 만들었다. 이번에는 모형이 아닌 자신을 마무리해줄 도구였다. 그렇게 눈물을 흘리며 폭탄을 점화 시켰고 기폭제가 터질 무렵, 그는 눈을 굳게 닫았다.`,
        `잠시 후, 그는 이상한 괴리감에 눈을 떴고 폭탄은 터지지 않았다. 다시 시도해 보았지만 폭탄은 결코 터지지 않았다. 그 후로도 여러번 시도했지만 매번 실패를 하자, 그는 폭탄을 분해해보았고, 결코 놀라지 않을 수 없었다. 발화기관과 기폭제가 있을 뿐 그가 실제 폭탄이라 소환했던 것은 동네 아이들에게 주었던 폭탄 모형과 같았다. 그렇다, 그는 실제 폭탄을 만들 순 없었고, 그 사실은 그를 더욱 머릴 아프게 했다.`
    ],
    [
        `그는 기억이 돌이켜 보며, 수상함을 느꼈다. 분명 자신은 실제 폭탄을 아이들에게 줄 수 없었지만, 수 많은 사상자를 낸건 진짜 폭탄 그 자체였다. 그렇다면, 누군가 바꿔치기 했을 가능성이 높으며, 그는 아무 죄 없이 죄를 뒤집어 쓰고 모든 걸 잃게 되었다는 것이다. 분노에 휩싸인 그는 자신이 소환할 수 있는 모든 것을 소환하기 시작했다. 그렇게 모든 것을 쏟아내던 중 의문의 메세지를 받게 되는데…`,
        `"미련한 짓 좀 하지마. 너가 시스템을 과부하를 주면 좋은 결말을 맞이 할 순 없어"`,
        `션은 그 메세지를 무시하고 더욱 더 빠르게 소환해 나갔고 점점 자신의 움직임이 더뎌진다라는 껄 느낄 수 있었다. 그러던 어느 순간 그는 정신을 잃고 쓰러지고 말았다.`
    ],
    [
        `그는 엄청난 폭발 소리와 함께 눈을 떴다. 뭔가 익숙한 공간… 션이 일어난 곳은 자신이 살던 동네였다. 당황할 틈도 없이 동네사람들이 찾아왔고, 그를 도와주려는 사람들이 먼저 찾아와 그를 지켜주려 했다. 이건 정확히 어제와 같다. 그는 당황할 틈도 없이 행동에 나섰다. 그의 기억속에 그를 지켜주려다 죽음을 맞이한 이들이 뇌리에 스쳐갔기 때문이다. 그는 재빨리 동네 사람들을 유인하고 도망쳤다. 그가 사랑하던 사람들의 무의미한 희생을 없애고 무사히 도망친 그는 희미한 미소를 띄었다.`,
        `어떻게 하루 전날로 돌아갔는지는 모르지만 무언가가 자신을 통제하고 있을음 어렴풋이 느낄 수 있었다. 션은 그럼에도 달라진 결과에 희망을 품었고, 다시 이솔과 만났던 호숫가로 향했다.`
    ],
    [
        `잠시후 그녀가 나타났지만 그녀는 다시금 당황스런 표정을 지으며 사라졌다. 그녀에게 전해줄 많은 말을 머금고 숙소로 향했다. 션은 그녀가 자신을 알아차리지 못했음을 느꼈고 무언가 잘못 돌아가고 있음에 그 상황을 타개하기 위한 방법들을 생각해 보았다. 문득 저번에 자신으로 온 메시지를 기억해 냈고, 그녀에게 자신이 겪은 일을 메시지로 보내기로 하였다. 장문의 메시지를 작성 후 보내기 눌렀으나 이상하게도 작동하지 않았다. 다양한 시도를 해보았으나 모두 실패하고 말았다. 그렇게 션은 그날을 마무리했다.`,
        `날이 밝자마자 그는 다시 메시지 창을 열었고 이솔에게 정보를 보낼 방법을 모색했다. 예기치 않게 새로운 메시지가 와 있었다.`,
        `"넌 그렇게 큰 힌트는 줄 수가 없어. 최대한 간략하게 애매하다면 모르겠지만…"`,
        `역시나 무언가 자신을 통제하고 감시하고 있음을 안 그는 "무언가 통제 당하는 기분, 그치만 작은 희망을 보았어요."라고 그녀에게 보내 보았고 역시나 메시지가 전송되었다는 알람을 받았다. 역시나 모든게 컨트롤 되고 있음이 확실시 되었다. 그럼에도 불구하고 그는 포기하고 싶지 않았다.`
    ],
    [
        `션은 모든 준비를 마치고 그녀를 만나기 위해 발걸음을 옮겼다. 기다림 끝에 이솔을 만날 수 있었고, 역시나 기억을 못하는 그녀를 위해 전과 같은 행동으로 감시를 체크했다. 그날 밤, 그는 몇 가지를 알 수 있었는데:`,
        `첫번 째, 그녀는 기억을 못하는 것 같다. 그럼에도 불구하고 그녀의 표정이나 눈빛은 처음과는 살짝 다르다. 그렇다는 건, 아마 그녀도 자신과 같이 무한의 굴레에 남겨졌으며 모든게 통제 당하는 것일수도 있다는 것.`,
        `두번 째, 포스트 잇에 작성한 메시지 중 몇 개를 다른 단어로 바꿔 썼음에도 아무런 인기척이나 메시지를 받지 못했다는 것.`,
        `세번 째, 그녀의 얼굴이 매우 낯이 익다는 것.`
    ],
    [
        `그는 다시금 야밤에 호숫가로 향했고 그가 기절하기 전까지 미친듯이 소환하기 시작했다.`,
        `얼마지나 폭발 소리와 함께 눈을 뜬 그는 익숙하단듯 자신의 사람들을 살리고 다시 호숫가로 향했다. 하루 휴식을 취하고 다음 날 아침 그는 다시 그녀에게 메시지를 남겼다.`,
        `"익숙해지지 마세요. 거기나 여기나 빠져나갈 길이 있을거라 믿습니다."`,
        `혹시나 메시지가 가지 않을 까 조마조마 했지만 성공적으로 보낼 수 있었다. 그 후 다시금 그녀를 만나 포스트 잇에 그가 아는 정보를 적기 시작했다. 놀랍게도 그녀는 놀라지도 않았으며, 그녀와 처음 만났을 때처럼 똑같은 행동을 유지 했다. 아마 그녀도 모든 행동에 감시 당하는 걸 인지하고 조심하는 듯 했다. 그러나 의외로 그녀가 먼저 다른 주제를 던지기 시작했다.`
    ],
    [
        `그녀는 떨리는 말투로 션에게 말했다.`,
        `"널 현실에서 마주친 적이 있는거 같아… 낯이 매우 익어…"`,
        `그녀의 떨리는 말투에서 그녀가 큰 문제를 직면하고 있음을 할 수 있었다. 션은 조심스레 포스트 잇에 적어 나갔다. `,
        `"혹시 근처에 위협이 될만한 사람이 있니?"`,
        `그의 질문에 그녀는 눈물을 터트리며 조용히 끄덕거리고 있었다. 그녀의 반응에 그는 다시 물었다.`,
        `"난 오늘 밤 나의 어제로 회기하거든… 너도 그런 반복을 하나 해서… 혹시 너도…?"`
    ],
    [
        `그의 물음에 그녀의 눈물이 양볼에 흘러내리며 아주 조용하게 흐느끼기 시작했다. 션은 왠지 이 회기라는 익숙한 패턴이 동일인물에 의한 의도적 범행으로 생각했다. 비슷한 시간대에 어제로 회기라… 그의 머릿 속에서 좋은 방법을 생각해냈다.`,
        `그는 그녀에게 자신이 시스템 과부하를 일으킬테니 얼른 상황에서 벗어나라고 전해 주었고, 그 쪽지를 보자마자 이솔은 션의 세계에서 황급히 사라졌다. 션은 다급히 자신이 할 수 있는 모든 소환을 함으로써 시스템 과부하를 일으켰다. 조금이라도 이솔을 위해서라도… 혹시나 같은 이가 그녀와 션을 억압하고 통제하는 거라면 반드시 도움이 될 테니까…`
    ]
  ];

  useEffect(() => {
    if (!user || !user.email) return;

    const fetchProgress = async () => {
      try {
        const userDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const visitCount_it_is_a_loop = data.visitCount_it_is_a_loop || 0;
          setVisitCount(visitCount_it_is_a_loop);
          setVisitCount_itisaloop(data.visitCount_it_is_a_loop || 0);
          setSolvedUrls(data.solvedUrls || []);
        }
      } catch (error) {
        setError("Error fetching progress.");
        console.error(error);
      }
    };

    fetchProgress();
  }, [user]);

  useEffect(() => {
    if (paragraphDict.length > 0) {
      const fetchAndUpdateTexts = async () => {
        try {
          const pageDocRef = doc(db, 'vron_progress', user.email);
          const docSnapshot = await getDoc(pageDocRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const visitCount_it_is_a_loop = data.visitCount_it_is_a_loop || 0;
            const updatedParagraphs = paragraphDict[currentGroupIndex].map((text) => {
              return text;
            });
            if (visitCount_it_is_a_loop > 1) {
              setDisplayedTexts(updatedParagraphs);
              setIsTyping(false);
            } else {
              typeTexts(updatedParagraphs);
            }
          }
        } catch (error) {
          setError("Error fetching progress for updating texts.");
          console.error(error);
        }
      };

      fetchAndUpdateTexts();
    }
  }, [currentGroupIndex, user]);

//   useEffect(() => {
//     const handleKeyPress = (event) => {
//       if (event.key === 'a') {
//         handleMoveToNextChapter(); // Navigate to the desired URL when 'a' is pressed
//       }
//     };
  
//     window.addEventListener('keydown', handleKeyPress);
  
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, [isTyping, currentGroupIndex]);

useEffect(() => {
    const handleClickOrSpace = (event) => {
      // Handle mouse click
      if (event.type === 'click' && !isTyping) {
        moveToNextGroup();
      }

      // Handle spacebar press
      if (event.type === 'keydown' && event.keyCode === 32 && !isTyping) {
        moveToNextGroup();
      }
    };

    window.addEventListener('click', handleClickOrSpace);
    window.addEventListener('keydown', handleClickOrSpace);

    return () => {
      window.removeEventListener('click', handleClickOrSpace);
      window.removeEventListener('keydown', handleClickOrSpace);
    };
  }, [isTyping, currentGroupIndex]);


  const typeTexts = (texts) => {
    setDisplayedTexts(Array(texts.length).fill(''));
    setIsTyping(true);
    let index = 0;
    const speed = 50;

    const typingEffect = () => {
      if (index < texts.length) {
        let currentText = texts[index];
        let currentIndex = 0;

        const typeParagraph = () => {
          if (currentIndex <= currentText.length) {
            setDisplayedTexts((prev) => {
              const newTexts = [...prev];
              newTexts[index] = currentText.substring(0, currentIndex);
              return newTexts;
            });
            currentIndex++;
            typingTimeout.current = setTimeout(typeParagraph, speed);
          } else {
            index++;
            typingEffect();
          }
        };

        typeParagraph();
      } else {
        setIsTyping(false);
      }
    };

    typingEffect();
  };

  const moveToNextGroup = () => {
    if (!isTyping && currentGroupIndex < paragraphDict.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    }
  };

//   const handleMoveToInitialPage = async () => {
//     try {
//       const pageDocRef = doc(db, 'vron_progress', user.email);
//       const docSnapshot = await getDoc(pageDocRef);

//       if (docSnapshot.exists()) {
//         const data = docSnapshot.data();
//         const visitCount = data.visitCount_it_is_a_loop ? data.visitCount_it_is_a_loop + 1 : 1;
//         const updatedSolvedUrls = [...new Set([...data.solvedUrls, 'it_is_a_loop'])];
//         await updateDoc(pageDocRef, { visitCount_it_is_a_loop: visitCount, solvedUrls: updatedSolvedUrls });
//         setVisitCountItisaloop(visitCount);
//       } else {
//         await setDoc(pageDocRef, { visitCount_it_is_a_loop: 1, solvedUrls: ['it_is_a_loop'] }, { merge: true });
//         setVisitCountItisaloop(1);
//       }

//       navigate('/vron/it_is_a_loop');
//     } catch (error) {
//       console.error("Error updating visit count for it_is_a_loop:", error);
//     }
//   };

  const handleMoveToNextChapter = async () => {
    try {
        const pageDocRef = doc(db, 'vron_progress', user.email);
        const docSnapshot = await getDoc(pageDocRef);
    
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const visitCount = data.visitCount_end_of_loop ? data.visitCount_end_of_loop + 1 : 1;
            const updatedSolvedUrls = [...new Set([...data.solvedUrls, 'end-of-loop'])];
            await updateDoc(pageDocRef, { visitCount_end_of_loop: visitCount, solvedUrls: updatedSolvedUrls });
            setVisitCountEndvisitCount_end_of_loop(visitCount);
        } else {
            await setDoc(pageDocRef, { visitCount_end_of_loop: 1, solvedUrls: ['end-of-loop'] }, { merge: true });
            setVisitCountEndvisitCount_end_of_loop(1);
        }
    
        navigate('/vron/end-of-loop');
    } catch (error) {
        console.error("Error updating visit count for end-of-loop:", error);
    }
  };

  return (
    <div className={styles.itisaloopContainer}>
      <header className={styles.itisaloopHeader}>
        <h1>Chapter: It is a loop</h1>
      </header>
      <main className={styles.itisaloopMain}>
        <img src={`/img/it-is-a-loop/${currentGroupIndex + 1}.jpg`} alt={`Image for group ${currentGroupIndex + 1}`} className={styles.paragraphImage} />
        {displayedTexts.map((text, index) => (
          <p key={index} className={styles.typingText} style={{ minHeight: 'unset' }} dangerouslySetInnerHTML={{ __html: text }}></p>
        ))}
        {!isTyping && currentGroupIndex === paragraphDict.length - 1 && (
          <button className={styles.nextButton} onClick={handleMoveToNextChapter}>Next</button>
        )}
      </main>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}

export default VRonItisaloop;